import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { RouteProps } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { sessionSlice } from "maven-lib/dist/states";
import { LoLang } from "@maven-rest/common/common.types";
import { UserWithInfo } from "@maven-rest/sso/User.dto";
import { UserFlax } from "@maven-rest/sso/User.flax";
import { getBrowserDefaultLanguage, LOCALE_LANG_KEY } from "@maven-rest/states/index";

interface MyInfoContextProps {
  myInfo?: UserWithInfo;
  lang: LoLang;
}

const MyInfoContext = createContext<MyInfoContextProps>({
  myInfo: undefined,
  lang: LoLang.KO,
});

const language = (localStorage.getItem(LOCALE_LANG_KEY) as LoLang) || getBrowserDefaultLanguage();

function MyInfoContextProvider({ myInfo, lang, children }: { myInfo?: UserWithInfo; lang?: LoLang; children: React.ReactNode }) {
  return <MyInfoContext.Provider value={{ myInfo, lang: language || LoLang.KO }}>{children}</MyInfoContext.Provider>;
}

function useMyInfoContext() {
  return useContext(MyInfoContext);
}

export { MyInfoContext, MyInfoContextProvider, useMyInfoContext };

interface MyInfoGuardProps extends RouteProps {}

export function MyInfoGuard({ component: Component }: MyInfoGuardProps) {
  const Comp: any = Component;
  const dispatch = useDispatch();

  return (
    <>
      <FxGuard
        key="static"
        api={{ ...UserFlax.getMyInfo({}) }}
        loading={() => <div className="app-spinner" />}
        render={(resp) => {
          if (resp) {
            return (
              <MyInfoContextProvider myInfo={resp}>
                <Comp currentUser={resp} lang={language} />
              </MyInfoContextProvider>
            );
          }

          return;
        }}
        error={(err) => {
          if (err?.code === "CREDENTIAL-INVALID") {
            dispatch(sessionSlice.actions.signOut());
            return <>&nbsp;</>;
          }

          return (
            <>
              [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
            </>
          );
        }}
      />
    </>
  );
}
