import {
  CheckIsPasswordConfiguredRequest,
  CheckIsPasswordConfiguredResponse,
  CreateUserRequest,
  CreateUserResponse,
  GetUserByAuthTokenRequest,
  GetUserResponseByToken,
  InitUserPasswordRequest,
  InitUserPasswordResponse,
  InitUserPasswordWithVerificationCodeRequest,
  InitUserPasswordWithVerificationCodeResponse,
  ResetExpiredUserPasswordRequest,
  ResetExpiredUserPasswordResponse,
  ResetUserPasswordRequest,
  ResetUserPasswordResponse,
} from "./User.dto";
import { UserUrl } from "./User.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

// Rest

export class UserFlax {
  public static getMyInfo(data: FxRequestData<GetUserByAuthTokenRequest>): FxApiRequestData<GetUserResponseByToken> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.getMyInfo, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createUser(data: FxRequestData<CreateUserRequest>): FxApiRequestData<CreateUserResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.createUser, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static checkIsPasswordConfigured(
    data: FxRequestData<CheckIsPasswordConfiguredRequest>
  ): FxApiRequestData<CheckIsPasswordConfiguredResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.checkIsPasswordConfigured, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static resetUserPassword(data: FxRequestData<ResetUserPasswordRequest>): FxApiRequestData<ResetUserPasswordResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.resetUserPassword, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static resetExpiredUserPassword(
    data: FxRequestData<ResetExpiredUserPasswordRequest>
  ): FxApiRequestData<ResetExpiredUserPasswordResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.resetExpiredUserPassword, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static initUserPassword(data: FxRequestData<InitUserPasswordRequest>): FxApiRequestData<InitUserPasswordResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.initUserPassword, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static initUserPasswordWithVerificationCode(
    data: FxRequestData<InitUserPasswordWithVerificationCodeRequest>
  ): FxApiRequestData<InitUserPasswordWithVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.initUserPasswordWithVerificationCode, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
