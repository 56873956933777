import { RestUrl } from "../common/cyan.types";

export class UserUrl {
  public static getMyInfo: RestUrl = ["GET", "/user/my"];
  public static createUser: RestUrl = ["POST", "/users"];
  public static checkIsPasswordConfigured: RestUrl = ["POST", "/user/check-is-password-configured"];
  public static resetUserPassword: RestUrl = ["POST", "/user/password-reset"];
  public static resetExpiredUserPassword: RestUrl = ["POST", "/user/expired-password-reset"];
  public static initUserPassword: RestUrl = ["POST", "/user/password-init"];
  public static initUserPasswordWithVerificationCode: RestUrl = ["POST", "/user/password-init-with-verification-code"];
}
