import { useCallback, useEffect, useRef, useState } from "react";

export function useThrottle<P>(delay: number, delegate: (params: P) => void, discardQueue: boolean = false) {
  const invokedAt = useRef<number>(0);
  const [state, setState] = useState(false);
  const [params, setParams] = useState<P>(null);
  const throttle = useCallback((args: P) => {
    if (!state) {
      setState(true);
      setParams(args);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if (state) {
        const invoke = () => {
          invokedAt.current = new Date().getTime();
          delegate(params);
          setState(false);
        };

        if (new Date().getTime() - invokedAt.current >= delay) {
          invoke();
          return () => {};
        }

        const timeout = setTimeout(invoke, delay);

        return () => {
          clearTimeout(timeout);
        };
      }

      return () => {};
    },
    discardQueue ? [state] : [state, params] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return throttle;
}
