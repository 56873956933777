import { css } from "@emotion/react";

export const globalStyles = css`
  .maven-docs {
    .flax.fx-guard.--loading {
      opacity: unset;
    }
  }

  .ms-ContextualMenu-item.feature-available--basic span,
  .ms-ContextualMenu-item.feature-available--standard span,
  .ms-ContextualMenu-item.feature-available--standard-archiver span,
  .ms-ContextualMenu-item.feature-available--pro span,
  em.feature-available--basic,
  em.feature-available--standard,
  em.feature-available--standard-archiver,
  em.feature-available--pro {
    &:after {
      content: "Plan";
      color: #fff;
      background: #000000;
      font-size: 11px;
      vertical-align: text-top;
      border-radius: 50rem;
      border-bottom-left-radius: 0;
      padding: 1px 4px;
      line-height: 100%;
      margin-left: 5px;
      text-indent: 0;
      position: relative;
      font-style: normal;
    }
  }

  .ms-ContextualMenu-item.feature-available--basic span,
  em.feature-available--basic {
    &:after {
      content: "Basic";
      background: #466be6;
    }
  }

  .ms-ContextualMenu-item.feature-available--standard span,
  em.feature-available--standard {
    &:after {
      content: "Standard";
      background: #d46d32;
    }
  }

  .ms-ContextualMenu-item.feature-available--standard-archiver span,
  em.feature-available--standard-archiver {
    &:after {
      content: "Standard + Archiver";
      background: #d46d32;
    }
  }

  .ms-ContextualMenu-item.feature-available--pro span,
  em.feature-available--pro {
    &:after {
      content: "Pro";
      background: #b93839;
    }
  }

  em.feature-available--basic:after {
    content: "Basic Plan";
  }
  em.feature-available--standard:after {
    content: "Standard Plan";
  }
  em.feature-available--standard-archiver:after {
    content: "Standard + Archiver Plan";
  }
  em.feature-available--pro:after {
    content: "Pro Plan";
  }
`;
