import { hrtime } from "process";
import dayjs from "dayjs";
import { intersection, isEqual, uniq } from "lodash";

export function uniqId(): string {
  return (BigInt(10 ** 18 * 5) + BigInt(BigInt(new Date().getTime() - 1600000000000) * BigInt(10000000)) + BigInt(hrtime()[1])).toString();
}

export function uniqIds(count: number): Array<string> {
  const ids: Set<string> = new Set();

  while (ids.size < count) {
    const id = uniqId();

    if (ids.has(id)) {
      continue;
    }

    ids.add(id);
  }

  return Array.from(ids).sort((a, b) => (BigInt(a) - BigInt(b) > BigInt(0) ? 1 : -1));
}

/**
 * @deprecated Don't use that function for type guards. Use the "in" keyword instead.
 */
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function arrayify<T>(e: T | T[]): Array<T> {
  return Array.isArray(e) ? (e as Array<T>) : [e];
}

export function findBigintSafe<T>(entities: T[], key: keyof T, search: bigint): T | null {
  return !!search && entities.find(entity => entity[key] && String(entity[key]) === String(search));
}

export function filterBigintSafe<T>(entities: T[], key: keyof T, search: bigint): T[] {
  return entities.filter(entity => entity[key] && String(entity[key]) === String(search));
}

export function compareBigintSafe(a: bigint | string | unknown, b: bigint | string | unknown): boolean {
  return String(a) === String(b);
}

export function tryParseBigInt(a: string | bigint | unknown): bigint | false {
  try {
    return BigInt(a as bigint);
  } catch (e) {
    return false;
  }
}

export function intersectionBigintSafe(a: Array<bigint | string>, b: Array<bigint | string> | bigint | string): Array<bigint> {
  return intersection(
    a.map<bigint>(e => tryParseBigInt(e) as bigint).filter(e => typeof e === "bigint"),
    arrayify(b)
      .map<bigint>(e => tryParseBigInt(e) as bigint)
      .filter(e => typeof e === "bigint")
  );
}

export function readableNumber(val: string | number): string {
  if (isNaN(Number(val))) return "0";

  const [intPartRaw, fracPartRaw] = String(val).split(".");
  const intPart = String(intPartRaw).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${intPart}${fracPartRaw ? "." : ""}${fracPartRaw || ""}`;
}

export function readablePhoneNumber(val: string, region: "KR" = "KR", hyphen = "-", masked?: boolean): string {
  if (!val) return val;

  if (masked) {
    val = val.replace(/[^0-9*]/gi, "");
  } else {
    val = val.replace(/[^0-9]/gi, "");
  }

  hyphen = hyphen || "-";

  if (val.length < 5) {
    return val;
  }

  if (region === "KR") {
    if (val.length === 5) {
      // 12-345
      return val.substring(0, 2) + hyphen + val.substring(2, 5);
    }

    if (val.length === 6) {
      // 123-456
      return val.substring(0, 3) + hyphen + val.substring(3, 6);
    }

    if (val.length === 7) {
      // 123-4567
      return val.substring(0, 3) + hyphen + val.substring(3, 7);
    }

    if (val.length === 8) {
      // 1234-5678
      return val.substring(0, 4) + hyphen + val.substring(4, 8);
    }

    if (val.length === 9) {
      // 12-345-6789
      return val.substring(0, 2) + hyphen + val.substring(2, 5) + hyphen + val.substring(5, 9);
    }

    if (val.length === 10) {
      // 12-3456-7890, 123-456-7890
      const p2 = val.substring(0, 2);

      if (p2 === "02") {
        return val.substring(0, 2) + hyphen + val.substring(2, 6) + hyphen + val.substring(6, 10);
      }

      return val.substring(0, 3) + hyphen + val.substring(3, 6) + hyphen + val.substring(6, 10);
    }

    if (val.length === 11) {
      // 123-4567-8901
      return val.substring(0, 3) + hyphen + val.substring(3, 7) + hyphen + val.substring(7, 11);
    }

    if (val.length === 12) {
      // 1234-5678-9012
      return val.substring(0, 4) + hyphen + val.substring(4, 8) + hyphen + val.substring(8, 12);
    }
  }

  return val;
}

export function classNames(
  ...args: Array<string | string[] | boolean | { [key: string]: boolean | undefined } | undefined | null>
): string {
  return args
    .reduce<Array<string>>((o, e) => {
      if (typeof e === "string" && e) o.push(e);
      else if (Array.isArray(e)) o.push(classNames(...e));
      else if (e && typeof e === "object") {
        Object.keys(e).forEach(k => {
          if (e[k]) o.push(k);
        });
      }

      return o;
    }, [])
    .join(" ");
}

export function secondsToReadableString(sec: number) {
  if (!sec) return "00:00";
  if (sec < 0) return "00:00";

  const hour = Math.floor(sec / (60 * 60));
  const minute = Math.floor((sec % (60 * 60)) / 60);
  const remainSec = Math.floor(sec % 60);
  const hourStr = `${hour}`.padStart(2, "0");
  const minuteStr = `${minute}`.padStart(2, "0");
  const secStr = `${remainSec}`.padStart(2, "0");

  return `${hour > 0 ? hourStr + ":" : ""}${minuteStr}:${secStr}`;
}

export function paramsMapped(params: { [key: string]: string }, path: string | string[]) {
  const paths = Array.isArray(path) ? path : [path];

  return paths.map(val => {
    if (typeof val !== "string") return val;

    Object.keys(params || {}).forEach(k => {
      val = val.split(`:${k}`).join(params[k]);
    });

    return val;
  });
}

export function patchPayload(payload: { [key: string]: any }, keyPathDelimiterByDot: string, value: any): { [key: string]: any } {
  let anchor: any = payload;

  const keys = keyPathDelimiterByDot.split(".");

  for (let key = keys.shift(); key; key = keys.shift()) {
    if (!hasOwnProperty(anchor, key)) {
      Object.assign(anchor, { [key]: {} });
    }

    if (keys.length) {
      anchor = anchor[key];
    } else {
      anchor[key] = value;
    }
  }

  return payload;
}

export function ellipsis(text: string, length: number, ellipsisSymbol = ".."): string {
  if (!text) return text;
  else if (text.length > length) return `${text.slice(0, length)}${ellipsisSymbol}`;
  else return String(text);
}

export function getCurrentDate(reserveMilliseconds?: boolean) {
  if (reserveMilliseconds === true) return dayjs().toDate();

  return dayjs().set("millisecond", 0).toDate();
}

type Difference<T extends Record<string, any>, P extends Record<string, any>> = {
  missingFromFirst: { [key in keyof P]?: P[key] };
  missingFromSecond: { [key in keyof T]?: T[key] };
  differences: { [key in keyof P]?: P[key] };
};

export function getDiff<T extends Record<string, any>, P extends Record<string, any>, K extends keyof T & keyof P>(
  obj1: T,
  obj2: P
): Difference<T, P> {
  const result: Difference<T, P> = {
    missingFromFirst: {},
    missingFromSecond: {},
    differences: {},
  };

  const obj1Keys = new Set<keyof T>(Object.keys(obj1));
  const obj2Keys = new Set<keyof P>(Object.keys(obj2));

  const uniqueKeys = uniq([...obj1Keys, ...obj2Keys]) as Array<K>;

  for (const key of uniqueKeys) {
    const inObj1 = obj1Keys.has(key);
    const inObj2 = obj2Keys.has(key);

    if (!inObj1 && !inObj2) {
      throw new Error("Unexpected Error; key is neither in first object nor in second object");
    }

    if (inObj1 && inObj2) {
      if (!isEqual(obj1[key], obj2[key])) {
        result.differences[key] = obj2[key];
      }
    } else if (inObj1) {
      result.missingFromSecond[key] = obj1[key];
    } else if (inObj2) {
      result.missingFromFirst[key] = obj2[key];
    }
  }

  return result;
}

export function nullToUndefined<K extends Record<string, any>, T extends K[keyof K]>(val?: T): T | undefined {
  return val !== null ? val : undefined;
}

export function removeUndefinedProperty<T extends Object>(obj: T): T {
  return (Object.keys(obj) as Array<keyof T>).reduce<T>(
    (prev, key) => {
      if (prev[key] === undefined) {
        delete prev[key as keyof T];
      }

      return prev;
    },
    { ...obj }
  );
}
